import React, { useState } from "react"
import { API_URL } from "../../../utils/helpers"
import Input from "../../form/Input"
import Msg from "../../form/Msg"
import Submit from "../../form/Submit"
import { Form, ButtonContainer } from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const SignIn = ({ signedIn }) => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/signin`)
    let data = new FormData()
    data.append("pwd", names.pwd || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setMsg({})
        signedIn(json.token)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Form method="post" onSubmit={e => handleSubmit(e)}>
      <div>
        <Input
          type="password"
          label="Password"
          req={true}
          name="pwd"
          value={names.pwd}
          update={handleUpdate}
          errors={errors}
        />
      </div>

      {msg.type && <Msg data={msg} />}

      <ButtonContainer className={msg.type === "working" ? "hidden" : ""}>
        <Submit name="Sign In" icon={faChevronCircleRight} />
      </ButtonContainer>
    </Form>
  )
}

export default SignIn
