import styled from "styled-components"
import { ButtonContainer as BaseButtonContainer } from "../../../elements/Layout/styled"

export const Form = styled.form`
  width: 50%;
`

export const ButtonContainer = styled(BaseButtonContainer)`
  padding-top: 30px;
  padding-bottom: 5px;
`
