import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import { API_URL, getToken } from "../../../utils/helpers"
import { Container, Table } from "./styled"

const Orders = () => {
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    init(0, getToken())
  }, [])

  const init = async (skip, token) => {
    const url = new URL(`${API_URL}/admin/transactionsInit`)
    const params = { skip, token }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setTransactions(json.transactions)
        setLoading(false)
      }
    } catch (error) {}
  }

  return (
    <Container>
      {loading ? (
        <div />
      ) : (
        <Fragment>
          <h2>Recent Orders</h2>
          <p>
            Displaying the 50 most recent orders received through the shop site,
            click ID for details.
          </p>

          <Table className="show-on-desktop">
            <thead>
              <tr>
                <th>Date</th>
                <th>ID</th>
                <th className="left">Name</th>
                <th>Amount</th>
                <th className="left">Status</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map(item => {
                return (
                  <tr key={item.id}>
                    <td className="center">
                      <span>{item.date}</span>
                    </td>
                    <td className="center">
                      <Link to={`/admin/${item.id}`}>
                        <span>{item.id}</span>
                      </Link>
                    </td>
                    <td className="left">
                      <span>{item.name}</span>
                    </td>
                    <td className="right">
                      <span>{item.amount}</span>
                    </td>
                    <td className="left">
                      <span>{item.status}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Fragment>
      )}
    </Container>
  )
}

export default Orders
