import React, { useState, useEffect, Fragment } from "react"
import { Router } from "@reach/router"
import { API_URL, getToken, setToken } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import PageHdr from "../components/PageHdr"
import Details from "../components/admin/Details"
import Orders from "../components/admin/Orders"
import SignIn from "../components/admin/SignIn"
import { Main, MainContainer } from "../components/admin/styled"

const Admin = () => {
  const [loading, setLoading] = useState(true)
  const [admin, setAdmin] = useState(false)

  useEffect(() => {
    if (getToken()) {
      init(getToken())
    } else {
      setLoading(false)
    }
  }, [])

  const init = async token => {
    const url = new URL(`${API_URL}/admin/authInit`)
    const params = { token }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setAdmin(true)
      }
      setLoading(false)
    } catch (error) {}
  }

  const handleSignedIn = token => {
    setToken(token)
    if (token) {
      setAdmin(true)
    }
  }

  return (
    <Layout>
      <Helmet name="Admin" description="" slug="admin" />

      <PageHdr title="Order Admin" />
      {loading ? (
        <div />
      ) : (
        <section>
          <Main>
            <MainContainer className="main-page">
              <article>
                {admin ? (
                  <Router>
                    <Orders path="/admin" />
                    <Details path="/admin/:transactionId" />
                  </Router>
                ) : (
                  <Fragment>
                    <h2>Sign In</h2>
                    <p>Password required to access order details.</p>
                    <SignIn signedIn={handleSignedIn} />
                  </Fragment>
                )}
              </article>
            </MainContainer>
          </Main>
        </section>
      )}
    </Layout>
  )
}

export default Admin
