import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import { API_URL, getToken } from "../../../utils/helpers"
import { Container, Table, Grid } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons"

const Details = ({ transactionId }) => {
  const [loading, setLoading] = useState(true)
  const [transaction, setTransaction] = useState({
    stripe: {},
    items: [],
    totals: {},
    contact: {},
    shipping: {},
    billing: {},
  })

  useEffect(() => {
    init(transactionId, getToken())
  }, [transactionId])

  const init = async (transactionId, token) => {
    const url = new URL(`${API_URL}/admin/transactionInit`)
    const params = { transactionId, token }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setTransaction(json.transaction)
        setLoading(false)
      }
    } catch (error) {}
  }

  return (
    <Container>
      {loading ? (
        <div />
      ) : (
        <Fragment>
          <h2>
            <Link to="/admin">
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span className="icon">Orders</span>{" "}
            </Link>

            <span className="icon">/</span>
            <span>Order Details</span>
          </h2>
          <p>
            Review the items, contact info and address details submitted with
            this order.
          </p>
          <table className="submitted">
            <tbody>
              <tr>
                <th className="right">Order Number:</th>
                <td>{transactionId}</td>
                <th
                  className={
                    transaction.method === "finance" ? "right hidden" : "right"
                  }
                >
                  Stripe Trans ID:
                </th>
                <td
                  className={
                    transaction.method === "finance" ? "left hidden" : "left"
                  }
                >
                  {transaction.stripe.id}
                </td>
              </tr>
              <tr>
                <th className="right">Date Submitted:</th>
                <td>{transaction.date}</td>
                <th
                  className={
                    transaction.method === "finance" ? "right hidden" : "right"
                  }
                >
                  Stripe Status:
                </th>
                <td
                  className={
                    transaction.method === "finance" ? "left hidden" : "left"
                  }
                >
                  {transaction.stripe.status}
                </td>
              </tr>
            </tbody>
          </table>

          <Table className="show-on-desktop">
            <thead>
              <tr>
                <th>Qty</th>
                <th className="left">Item</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {transaction.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="center">
                      <span>{item.qty}</span>
                    </td>
                    <td>
                      <div>
                        <span>{item.item}</span>
                        <small>{item.subitem}</small>
                      </div>
                    </td>
                    <td className="right">{item.price}</td>
                    <td className="right">{item.subTotal}</td>
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3" className="right">
                  Sales Tax
                </td>
                <td className="right">{transaction.totals.tax}</td>
              </tr>
              <tr>
                <td colSpan="3" className="right">
                  Total
                </td>
                <td className="right">{transaction.totals.total}</td>
              </tr>
              <tr>
                <td colSpan="3" className="right">
                  {transaction.totals.paid}
                </td>
                <td className="right">{transaction.totals.amt}</td>
              </tr>
            </tfoot>
          </Table>

          <Grid>
            <p>Contact</p>
            <p>Shipping / Install</p>
            <p>Billing</p>
            <div>
              <span>{`${transaction.contact.fname} ${transaction.contact.lname}`}</span>
              <span>{transaction.contact.email}</span>
              <span>{transaction.contact.phone}</span>
            </div>

            <div className="shipping">
              <span>{`${transaction.shipping.address}`}</span>
              {transaction.shipping.address2 && (
                <span>{`${transaction.shipping.address2}`}</span>
              )}
              <span>{`${transaction.shipping.city}, ${transaction.shipping.st} ${transaction.shipping.zip}`}</span>
            </div>

            <div className="billing">
              <span>{transaction.billing.address}</span>
              {transaction.billing.address2 && (
                <span>{`${transaction.billing.address2}`}</span>
              )}
              <span>{`${transaction.billing.city}, ${transaction.billing.st} ${transaction.billing.zip}`}</span>
            </div>
          </Grid>

          <table className="submitted notes">
            <tbody>
              <tr>
                <th className="left">Installation / Order Notes:</th>
              </tr>
              <tr>
                <td className="left">
                  {transaction.shipping.notes ? (
                    <span>{transaction.shipping.notes}</span>
                  ) : (
                    <span className="invisible">none submitted</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </Container>
  )
}

export default Details
